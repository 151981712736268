import pick from 'lodash/pick';

import { decamelize } from 'helpers/camelize';
import {
  fetchWrapper,
  handleResponse,
  formatBody,
  hcUrl,
  buildOpts,
  objectToQueryString,
} from 'services/RequestService/helpers';

const getPreferences = async () => {
  const response = await fetchWrapper(hcUrl('/sign_up/preferences', buildOpts()));
  return handleResponse(response);
};

const updatePreferences = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/sign_up/preferences'),
    buildOpts({
      method: 'PATCH',
      body: formatBody({ preferences: params }),
    })
  );
  return handleResponse(response);
};

const availableZipCode = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/sign_up/zip_codes'),
    buildOpts({
      method: 'POST',
      body: formatBody(params),
    })
  );
  return handleResponse(response);
};

const getDeliveryOptions = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl(`/sign_up/delivery_options?${objectToQueryString(decamelize(params, true))}`),
    buildOpts()
  );
  return handleResponse(response);
};

const getWelcome = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl(`/sign_up/welcome?${objectToQueryString(decamelize(params, true))}`),
    buildOpts()
  );
  return handleResponse(response);
};

const getFunnelUser = async () => {
  const response = await fetchWrapper(hcUrl('/sign_up/welcome/user'), buildOpts());
  return handleResponse(response);
};

const createWelcome = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/sign_up/welcome'),
    buildOpts({
      method: 'POST',
      body: formatBody(
        {
          welcome: {
            ...params,
            signup_zip: params.signupZip,
            signup_voucher: params.signupVoucher,
            meal_kit_traits: params.mealKitTraits,
            experiment_variant_assignments: params.experimentVariantAssignments,
          },
        },
        true
      ),
    })
  );
  return handleResponse(response);
};

const updateWelcome = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/sign_up/welcome'),
    buildOpts({
      method: 'PATCH',
      body: formatBody(
        {
          welcome: {
            ...params,
            signup_zip: params.signupZip,
            signup_voucher: params.signupVoucher,
            meal_kit_traits: params.mealKitTraits,
          },
        },
        true
      ),
    })
  );
  return handleResponse(response);
};

const addVoucher = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/sign_up/signup_voucher'),
    buildOpts({
      method: 'POST',
      body: formatBody({ signupVoucher: { ...params, voucherCode: params.promoCode } }),
    })
  );
  return handleResponse(response);
};

const getVoucher = async () => {
  const response = await fetchWrapper(hcUrl('/sign_up/signup_voucher'));
  return handleResponse(response);
};

const createCheckout = async ({ params }) => {
  const mobileAppParams = params.mobileSignUp
    ? { mobile_sign_up: params.mobileSignUp, password: params.password }
    : {};

  const response = await fetchWrapper(
    hcUrl('/sign_up/checkouts'),
    buildOpts({
      method: 'POST',
      body: formatBody({
        payment: {
          ...pick(params, [
            'applePayEmail',
            'cardToken',
            'paypalEmail',
            'vendor',
            'paymentMethodId',
          ]),
          termsAgreedOn: params.termsChecked,
          ...mobileAppParams,
        },
        user: {
          ...pick(params, ['firstDeliveryDate', 'streetAddress', 'city', 'state']),
          name: params.fullName,
          address2: params.apartmentNumber,
          phone: params.phoneNumber,
          zip: params.zipCode,
        },
      }),
    })
  );
  return handleResponse(response);
};

const getBox = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl(`/sign_up/box?${objectToQueryString(decamelize(params, true))}`),
    buildOpts()
  );
  return handleResponse(response);
};

const updateBox = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/sign_up/box'),
    buildOpts({
      method: 'PATCH',
      body: formatBody(params),
    })
  );
  return handleResponse(response);
};

const createEvents = async ({ params }) => {
  const location = window.location.href;
  const eventDeduplicationId = document
    .getElementById('hc-sign-up-funnel-react-app')
    ?.getAttribute('data-event-deduplication-id');

  const response = await fetchWrapper(
    hcUrl('/sign_up/events'),
    buildOpts({
      method: 'POST',
      body: formatBody({
        event: {
          eventName: params.event_name,
          originalUrl: location,
          eventDeduplicationId,
        },
      }),
    })
  );
  return handleResponse(response);
};

const updateMealPlan = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/meal_plans'),
    buildOpts({
      method: 'PATCH',
      body: formatBody({ meal_plan: { meal_plan_slug: params.mealPlanSlug } }),
    })
  );
  return handleResponse(response);
};

const getBoxSignupAnswers = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl(`/sign_up/box_recommendations?${objectToQueryString(decamelize(params, true))}`),
    buildOpts()
  );
  return handleResponse(response);
};

const updateBoxSignupAnswerSelections = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/sign_up/box_recommendations'),
    buildOpts({
      method: 'PATCH',
      body: formatBody(params),
    })
  );
  return handleResponse(response);
};

const getRecommendedBox = async () => {
  const response = await fetchWrapper(hcUrl('/sign_up/recommended_box'), buildOpts());
  return handleResponse(response);
};

const getPartnership = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl(`/sign_up/partnership?${objectToQueryString(decamelize(params, true))}`),
    buildOpts()
  );
  return handleResponse(response);
};

const getPartnerships = async () => {
  const response = await fetchWrapper(hcUrl('/sign_up/partnerships'), buildOpts());
  return handleResponse(response);
};

const getPickMealsMenu = async () => {
  const response = await fetchWrapper(hcUrl('/sign_up/pick_your_meals/menu'), buildOpts());
  return handleResponse(response);
};

const getPickMealsPPS = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl(
      `/sign_up/pick_your_meals/price_per_serving?${objectToQueryString(decamelize(params, true))}`
    ),
    buildOpts()
  );
  return handleResponse(response);
};

const updatePickMealsOrderSummary = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/sign_up/pick_your_meals/order_summary'),
    buildOpts({
      method: 'PATCH',
      body: formatBody(params),
    })
  );
  return handleResponse(response);
};

const getPickMealsBasket = async () => {
  const response = await fetchWrapper(hcUrl('/sign_up/pick_your_meals/basket'), buildOpts());
  return handleResponse(response);
};

export {
  getPreferences,
  updatePreferences,
  availableZipCode,
  getDeliveryOptions,
  getVoucher,
  addVoucher,
  getWelcome,
  getFunnelUser,
  createWelcome,
  updateWelcome,
  createCheckout,
  getBox,
  updateBox,
  createEvents,
  updateMealPlan,
  getBoxSignupAnswers,
  updateBoxSignupAnswerSelections,
  getRecommendedBox,
  getPartnership,
  getPartnerships,
  getPickMealsMenu,
  getPickMealsPPS,
  updatePickMealsOrderSummary,
  getPickMealsBasket,
};
