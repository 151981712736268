// Vouchers
export const SVS_CODE_LENGTH = 19;
export const SVS_CODE_INDICATOR = '600649';
export const FUEL_VOUCHER_CODES = {
  FUEL_1: 'FUEL1',
  FUEL_2: 'FUEL2',
};

export const AMEXBCE_PROMO_CODE = 'AMEXBCE15';

// Servings / Meals Per Week
export const HOME_CHEF_MEALS_PER_WEEK = [2, 3, 4, 5, 6];
export const STANDARD_SERVINGS_PER_MEAL = [2, 4, 6];
export const FAMILY_SERVINGS_PER_MEAL = [4, 8];
export const TEMPO_PLAN_MEALS_PER_WEEK = [6, 8, 10, 12, 14, 16];
export const REACTIVATION_TEMPO_PLAN_MEALS_PER_WEEK = [6, 8, 10, 12, 14, 16, 20];

// Local Storage Keys
export const SHOW_PROMO_BANNER_LOCAL_STORAGE = 'showPromoBanner';
export const PICK_MEALS_SELECTIONS_MENU_ID_LOCAL_STORAGE = 'pickYourMealsSelectionsMenuId';
export const INTRO_SMS_OPT_IN = 'introSmsOptIn';
export const HEROES_REFERRER = 'heroesReferrer';

// Offer Page Query Parameters
export const MEALS_PARAM = 'meals';

// User Statuses
export const Statuses = {
  PICK_PLAN_FAMILY_STEP: 'on_plans_step',
  DIETARY_PREFERENCES_STEP: 'on_preference_dietary_step',
  DIETARY_AVOID_STEP: 'on_preference_avoid_step',
  PROMO_STEP: 'on_promo_step',
  BOX_PEOPLE_STEP: 'on_box_people_step',
  BOX_STEP: 'on_build_a_box_step',
  BILLING_STEP: 'on_billing_step',
  THANK_YOU_STEP: 'on_thankyou_step',
  FUNNEL_COMPLETE: 'funnel_complete',
  PICK_MEALS_BILLING_STEP: 'on_pick_meals_billing_step',
  PICK_MEALS_THANK_YOU_STEP: 'on_pick_meals_thank_you_step',
};

export const SKIP_PATHS = {
  INTRO: 'toIntro',
  PREF_DIETARY: 'toPreferencesDietary',
  PROMO: 'toPromo',
  BOX_PEOPLE: 'toBoxPeople',
  BOX: 'toBox',
  BILLING: 'toBilling',
  THANK_YOU: 'toThankYou',
};

// Partnerships
export const SPONGEBOB = 'spongebob';
export const AYESHA_CURRY = 'ayesha-curry';
export const MINECRAFT = 'minecraft';

export const PARTNERSHIP_RESOURCE_TYPES = {
  LOGO: 'logo',
  MEAL_IMAGE: 'meal_image',
  BADGE: 'badge',
  INTRO_IMAGE: 'intro_image',
};

// Pick Your Meals
export const PICK_YOUR_MEALS_SELECTIONS = 'pickYourMealsSelections';
export const PICK_YOUR_MEALS_MENU_ID = 'pickYourMealsMenuId';
