import { decamelize } from 'helpers/camelize';
import {
  fetchWrapper,
  handleResponse,
  hcUrl,
  buildOpts,
  transformMealResponse,
  objectToQueryString,
} from 'services/RequestService/helpers';

export const getNutritionalFacts = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl(
      `/nutritional_facts/${params.mealId}${params.packingFacility ? `?${objectToQueryString(decamelize({ packingFacility: params.packingFacility }, true))}` : ''}`
    ),
    buildOpts()
  );
  return handleResponse(response, transformMealResponse);
};
